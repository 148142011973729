// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
//
// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")

require("jquery") // Don't really need to require this...
require("jquery-ui")

$(function(){
    $('.datePicker').datepicker( {
        closeText: "Sluiten",
        prevText: "←",
        nextText: "→",
        currentText: "Vandaag",
        monthNames: [ "januari", "februari", "maart", "april", "mei", "juni",
            "juli", "augustus", "september", "oktober", "november", "december" ],
        monthNamesShort: [ "jan", "feb", "mrt", "apr", "mei", "jun",
            "jul", "aug", "sep", "okt", "nov", "dec" ],
        dayNames: [ "zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag" ],
        dayNamesShort: [ "zon", "maa", "din", "woe", "don", "vri", "zat" ],
        dayNamesMin: [ "zo", "ma", "di", "wo", "do", "vr", "za" ],
        weekHeader: "Wk",
        dateFormat: "dd/mm/yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
        dateFormat: 'yymmdd',
        changeYear: true,
        yearRange: "1900:c"
    } );

    $('#part_of_year').on('change', function(e){
        if(this.checked){
            $('.period').removeClass('ui-helper-hidden')
        }else{
            $('.period').addClass('ui-helper-hidden')
        }
    })

    $('#check_all_stations').click(function(event) {
        if(this.checked) {
            // Iterate each checkbox
            $('.stationCheck').each(function() {
                this.checked = true;
            });
        } else {
            $('.stationCheck').each(function() {
                this.checked = false;
            });
        }
    });

    $('#check_all_vars').click(function(event) {
        if(this.checked) {
            // Iterate each checkbox
            $('.varCheck').each(function() {
                this.checked = true;
            });
        } else {
            $('.varCheck').each(function() {
                this.checked = false;
            });
        }
    });

})
